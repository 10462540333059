<template>
  <div id="add-doctor">
    <h1>Add Doctor</h1>
    <form class="mt-5">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="name"
              v-model="doctor.name"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="email">E-mail</label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="email"
              v-model="doctor.email"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="speciality">Speciality</label>
            <b-form-select
              v-model="doctor.speciality"
              :options="specialities"
            ></b-form-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="speciality">Governorate</label>
            <b-form-select
              v-model="doctor.governorate"
              :options="governorates"
              text-field="governorate_name_en"
            ></b-form-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="address">Address</label>
            <input
              type="text"
              class="form-control"
              id="address"
              placeholder="Address"
              v-model="doctor.address"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="mobile-no">Mobile Number</label>
            <input
              type="text"
              class="form-control"
              id="mobile-no"
              placeholder="mobile number"
              v-model="doctor.mobileNo"
            />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button @click.prevent="onSubmit" class="btn btn-primary">
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import constants from "@/common/config/constants.json";

export default {
  data() {
    return {
      doctor: {
        name: null,
        email: null,
        address: null,
        mobileNo: null,
        speciality: null,
        governorate: null,
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      saveDoctor: "saveDoctor",
    }),
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const doctor = {
        name: this.doctor.name,
        email: this.doctor.email,
        address: this.doctor.address,
        mobileNo: this.doctor.mobileNo,
      };

      this.saveDoctor({
        vm: this,
        data: {
          doctor,
          byEvent: false,
        },
      });
    },
    resetFields() {
      this.doctor = {
        firstName: null,
        lastName: null,
        email: null,
        address: null,
        mobileNo: null,
      };
    },
  },
  computed: {
    specialities: () => {
      return constants.specialities;
    },
    governorates() {
      return constants.governorates;
    },
  },
};
</script>

<style lang="scss" scoped>
#add-doctor {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }
}
</style>
